import React, { useEffect, useState } from "react"
import { Router, Location } from "@reach/router"
import GlobalStates from "../components/globalstates"
import Layout from "../components/layout"

import { TransitionGroup, CSSTransition } from "react-transition-group"

import "../assets/scss/typography.scss"
import "../assets/css/layout.css"

import Track from "../components/OrderTrack"
import { ThemeProvider } from "@mui/material"
import { defaultTheme } from "../themes"

import Loadable from "react-loadable"
import SplashScreen from "../components/SplashScreen"

import Preloader from "../components/SparklingPreloader"

import checkoutDetection from "../services/react-native/checkoutDetection"

import { withDeferRender, DeferRenderProvider } from 'react-defer-renderer'
let delay = 600



const IndexPage = withDeferRender(Loadable({
  loader: () => import("../components/MasterIndexPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const Homemade = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/MainIndexPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const SearchPage = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/SearchPage/SearchPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const NotificationPage = withDeferRender(Loadable({
  loader: () =>
    import("../components/Homemade/NotificationPage/NotificationPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const Account = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/AccountPage/AccountPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const Shop = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/ShopPage/ShopPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const Product = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/ProductPage/ProductPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const Registration = withDeferRender(Loadable({
  loader: () => import("../components/RegistrationPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const ResetPassword = withDeferRender(Loadable({
  loader: () => import("../components/ResetPasswordPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const UserDetails = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/AccountPage/UserDetailsPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const UserUpdate = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/AccountPage/UpdateAccountPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const PurchaseHistory = withDeferRender(Loadable({
  loader: () =>
    import("../components/Homemade/AccountPage/PurchaseHistoryPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const PurchaseHistoryItem = withDeferRender(Loadable({
  loader: () =>
    import("../components/Homemade/AccountPage/PurchaseHistoryItem"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const MapPage = withDeferRender(Loadable({
  loader: () => import("../components/MapPicker/MapPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const AddressSearchPage = withDeferRender(Loadable({
  loader: () => import("../components/MapPicker/AddressSearchPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const BagPage = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/BagPage/BagPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const CheckoutPage = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/CheckoutPage/CheckoutPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const OngoingOrderDetails = withDeferRender(Loadable({
  loader: () =>
    import("../components/Homemade/OngoingOrderPage/OngoingOrderDetails"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const RatingsPage = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/RatingsPage/RatingsPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const SubmitRatingsPage = withDeferRender(Loadable({
  loader: () => import("../components/Homemade/RatingsPage/SubmitRatingsPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const HelpCenter = withDeferRender(Loadable({
  loader: () => import("../components/HelpCenter/HelpCenter"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const TawkToPage = withDeferRender(Loadable({
  loader: () => import("../components/HelpCenter/components/TawkToPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const AirSparkleGame = withDeferRender(Loadable({
  loader: () => import("../components/air"),
  loading: Preloader,
}), {fallback: <Preloader/>})

const SparkExpress = withDeferRender(Loadable({
  loader: () => import("../components/sparkexpress"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const VortexTopUpPage = withDeferRender(Loadable({
  loader: () => import("../components/Vortex/pages/VortexTopUpPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const VortexBillsPaymentPage = withDeferRender(Loadable({
  loader: () => import("../components/Vortex/pages/VortexBillsPaymentPage"),
  loading: Preloader,
  delay: delay,
}),{fallback: <Preloader/>})

const VortexVoucherPage = withDeferRender(Loadable({
  loader: () => import("../components/Vortex/pages/VortexVoucherPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const VortexTransactionHistory = withDeferRender(Loadable({
  loader: () => import("../components/Vortex/pages/VortexTransactionHistory"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const VortexTransactionHistoryDetails = withDeferRender(Loadable({
  loader: () =>
    import("../components/Vortex/pages/VortexTransactionHistoryDetails"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const PaymongoRefundDetailsPage = withDeferRender(Loadable({
  loader: () =>
    import("../services/paymongo/components/pages/PaymongoRefundDetailsPage"),
  loading: Preloader,
  delay: delay,
}), {fallback: <Preloader/>})

const Application = () => {
  console.log("render app here")

  useEffect(() => {
    checkoutDetection()
  }, [])

  if (typeof window !== "undefined" && window.ReactNativeWebView) {
    //
    let location = window.location.pathname

    if (location === "/express")
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "SPARK_EXPRESS",
        })
      )
    else
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "SPARKLE",
        })
      )
  }

  return (
    <>
      {/*<PushNotifications file={notificationServiceWorker} />*/}
      <ThemeProvider theme={defaultTheme}>
        <GlobalStates>
          <Layout>
            <DeferRenderProvider>
              <Location>
                {({ location }) => (
                  <Router>
                    <IndexPage path="/" />
                    <Homemade path="/food" />
                    <SearchPage path="/search" />
                    <NotificationPage path="/alerts" />
                    <Account path="/account" />
                    <Shop path="/shop" />
                    <Shop path="/shop/:shopId" />
                    <Product path="/product" />
                    <Registration path="/registration" />
                    <ResetPassword path="/reset" />
                    <UserDetails path="/user" />
                    <UserUpdate path="/user/update" />
                    <PurchaseHistory path="/user/history" />
                    <PurchaseHistoryItem path="user/history/:id" />
                    <MapPage path="/MapPage" />
                    <AddressSearchPage path="/AddressSearchPage" />
                    <BagPage path="/bagpage" />
                    <CheckoutPage path="/checkoutpage" />
                    <OngoingOrderDetails path="/ongoingOrderDetails" />
                    <RatingsPage path="/ratingspage" />
                    <SubmitRatingsPage path="/submitratingspage" />
                    <HelpCenter path="/helpcenter" />
                    <TawkToPage path="/chatsupportpage" />
                    <AirSparkleGame path="/air" />
                    <SparkExpress path="/express" />
                    <Track path="ongoingOrderDetails/track/:orderId" />
                    <VortexTopUpPage path="/vortextopup" />
                    <VortexBillsPaymentPage path="/vortexbillspayment" />
                    <VortexVoucherPage path="/vortexvoucher" />
                    <VortexTransactionHistory path="/vortextransactions" />
                    <VortexTransactionHistoryDetails path="/vortextransactions/:type/:refId" />
                    <PaymongoRefundDetailsPage path="/paymongo/refund/details/:refId" />
                  </Router>
                )}
              </Location>
            </DeferRenderProvider>
          </Layout>
        </GlobalStates>
      </ThemeProvider>
    </>
  )
}

const App = ({ data }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    let mounted = true

    if (mounted)
      setTimeout(() => {
        setLoaded(true)
      }, 2000)

    return () => {
      mounted = false
    }
  }, [])

  return <>{loaded ? <Application /> : <SplashScreen />}</>
}

export default App
